import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.loadingText =
      this.element.getAttribute("data-loading-text") || "Loading...";
    this.method = this.element.getAttribute("data-method") || "POST";

    this.element.addEventListener("click", (event) => {
      this._post(event);
    });
  }

  _post(event) {
    event.preventDefault();

    event.target.disabled = true;
    event.target.classList.add("disabled");
    event.target.innerHTML = `${this.loadingText}`;

    const url = event.target.getAttribute("href");

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.csrfToken(),
      },
    }).then((response) => {
      if (response.ok) {
      } else {
        event.target.disabled = false;
        event.target.innerHTML = "Error. Retry?";
        console.log("Error: ", response);
      }
    });
  }

  csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }
}
