import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!("theme" in localStorage)) {
      this._startWatching();
    }
  }

  dark(e) {
    e.preventDefault();
    localStorage.theme = "dark";
    document.dispatchEvent(new Event("darkmode:on"));
    document.documentElement.classList.add("dark");
  }

  light(e) {
    e.preventDefault();
    localStorage.theme = "light";
    document.dispatchEvent(new Event("darkmode:off"));
    document.documentElement.classList.remove("dark");
  }

  system(e) {
    e.preventDefault();
    localStorage.removeItem("theme");
    document.dispatchEvent(new Event("darkmode:system"));
    this._startWatching();
  }

  _startWatching() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", ({ matches }) => {
        if (matches) {
          this.dark();
        } else {
          this.light();
        }
      });
  }
}
