import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["window"];

  connect() {
    enter(this.element);
    enter(this.windowTarget);
  }

  close() {
    Promise.all([leave(this.element), leave(this.windowTarget)]).then(() => {
      this.element.remove();
      this.modalTurboFrame.src = null;
    });
  }

  escClose(event) {
    if (event.key === "Escape") this.close();
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
