import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textarea", "buttons"];

  connect() {
    // save the initial value of the textarea
    this.initialValue = this.textareaTarget.value;
  }

  update(event) {
    this.buttonsTarget.classList.remove("hidden");
  }

  discard(event) {
    this.textareaTarget.value = this.initialValue;
    this.buttonsTarget.classList.add("hidden");
  }

  save(event) {
    this.initialValue = this.textareaTarget.value;
    this.buttonsTarget.classList.add("hidden");
  }
}
